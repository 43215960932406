import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class Join extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      swiperQuote : viewStorage.current.querySelector('.Quote__slider .swiper'),
      swiperQuoteNavigation : viewStorage.current.querySelector('.Quote__title .swiper-navigation'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Join Us`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { swiperQuote } = this.DOM
    swiperQuote && this.initSwiperQuote()
  }

  initSwiperQuote() {
    const { swiperQuote, swiperQuoteNavigation } = this.DOM
    this.swiperQuote = new WknSwiper(swiperQuote, { 
      slidesPerView: "auto",
      spaceBetween: 24,
      breakpoints: {
        768: {
          slidesPerView: 1,
        }
      },
      navigation: {
        nextEl: swiperQuoteNavigation.querySelector('.next'),
        prevEl: swiperQuoteNavigation.querySelector('.prev')
      }
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { swiperQuote } = this
    swiperQuote && swiperQuote.destroy()
  }
}
