import { isDEVMODE, globalStorage, domStorage } from '../_globals'

export default class Header {
  constructor() {
    this.DOM = { el: domStorage.header }
    this.DOM.brand = this.DOM.el.querySelector('.brand')
    this.DOM.toggle = this.DOM.el.querySelector('.Nav__toggle')
    this.DOM.menuContainer = this.DOM.el.querySelector('.Nav__container')
    this.DOM.navWithChildren = this.DOM.el.querySelectorAll('.Nav__item.--hasChildren')
    this.DOM.cards = this.DOM.el.querySelectorAll('.Expertise__cards a')

    this.setEvents()
  }

  setEvents() {
    const { overlay } = domStorage
    const { navWithChildren, toggle, cards } = this.DOM

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.menuOpen === true ? this.close() : this.open()
    })

    globalStorage.openMobileMenu = () => this.open()
    globalStorage.closeMobileMenu = () => this.close()

    // overlay
    overlay.addEventListener('click', (e) => {
      if (globalStorage.menuOpen) this.close()
    })

    // SubMenus
    this.onSubmenu = this.onSubmenu.bind(this)
    navWithChildren.forEach(link => {
      link.addEventListener('mouseenter', this.onSubmenu)
      link.addEventListener('mouseleave', this.onSubmenu)
    });

    // Remove showsubmenu
    // cards.forEach(card => {
    //   card.addEventListener('click', (e) => {
    //     console.log('click card')
    //     domStorage.body.classList.remove('showSubMenu')
    //   })
    // });

    // Resize
    this.resizeTimeout = setTimeout(() => {}, 0)
    this.onResize = this.onResize.bind(this)
    this.windowWidthResize = window.innerWidth
    window.addEventListener('resize', this.onResize)
  }

  open() {
    // if (isDEVMODE) console.log('Open Menu')

    globalStorage.menuOpen = true
    domStorage.body.classList.add('showMenu')
    domStorage.body.style.overflow = 'hidden'

  }

  close() {
    // if (isDEVMODE) console.log('Close Menu')

    globalStorage.menuOpen = false
    domStorage.body.classList.remove('showMenu')
    domStorage.body.style.overflow = ''
  }

  onSubmenu() {
    const { body } = domStorage
     body.classList.toggle('showSubMenu')
  }

  onResize() {
    clearTimeout(this.resizeTimeout)
    this.resizeTimeout = setTimeout(() => {
      if (this.windowWidthResize !== window.innerWidth) {
        // if (isDEVMODE) console.log('updateHeaderResize')
        this.windowWidthResize = window.innerWidth
        if (globalStorage.menuOpen === true) this.close()
      }
    }, 250)
  }
}
