import {Renderer} from '@unseenco/taxi';
import {viewStorage} from '../_globals'
import Form from "../modules/form";

export default class SingleJob extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      applyForm: viewStorage.current.querySelector('.Form'),
      fileInputs: viewStorage.current.querySelectorAll('input[type=file]')
    }

    this.init()
  }

  init() {

    const {
      applyForm = null,
      fileInputs = null
    } = this.DOM

    if (applyForm) this.applyFormModule = new Form(applyForm, 'apply')
    fileInputs && fileInputs.forEach(fileInput => fileInput.addEventListener('change', this.updateFileName))
  }

  updateFileName(e) {
    const {currentTarget} = e
    const {parentNode} = currentTarget
    const {lastElementChild} = parentNode

    if (lastElementChild) lastElementChild.remove()

    let fileName = currentTarget.value.split('\\').pop().substring(0, 25)
    // compare fileName length with 25
    const hasBeenTruncated = fileName.length > 25
    if (hasBeenTruncated) {
      fileName = fileName + '...'
    }

    const span = document.createElement('span')
    span.classList.add('Form__file-name', '--error')
    span.textContent = fileName

    parentNode.appendChild(span)
  }

  onLeaveCompleted() {
    const { fileInputs } = this.DOM
    fileInputs && fileInputs.forEach(fileInput => fileInput.removeEventListener('change', this.updateFileName))
    this.applyFormModule && this.applyFormModule.destroy()
  }
}
