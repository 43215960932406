import { isDEVMODE, globalStorage, domStorage } from '../_globals'

export default class Popin {
  constructor() {
    this.DOM = { el: domStorage.body }
    this.DOM.popin = this.DOM.el.querySelector('.popin')
    this.DOM.toggle = this.DOM.el.querySelector('.popin__close')

    this.setEvents()
  }

  setEvents() {
    const { toggle } = this.DOM

    // Toggle
    toggle.addEventListener('click', (e) => {
      e.preventDefault()
      globalStorage.popinOpen === true ? this.open() : this.close()
    })

    globalStorage.openPopin = () => this.open()
    globalStorage.closePopin = () => this.close()

  }

  open() {
    console.log('Open Popin')

    // globalStorage.popinOpen = true

  }

  close() {
    const { popin } = this.DOM
    if (isDEVMODE) console.log('Close Popin')
    popin.classList.remove('--opened')
    globalStorage.popinOpen = false
  }
}
