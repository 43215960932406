import {Renderer} from '@unseenco/taxi';
import {viewStorage} from '../_globals'
import { gsap } from 'gsap'

export default class PagePresentation extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      heroGallery: viewStorage.current.querySelector('.Hero .gallery')
    }

    this.init()
  }

  init() {
    const { heroGallery } = this.DOM
    if (heroGallery) this.iniGallery()
  }

  iniGallery() {

    const { heroGallery } = this.DOM
    const visuals = heroGallery.querySelectorAll('.visual__container')

    this.galleryTL = gsap.timeline({
      paused: true,
      defaults: {
        duration: 1,
        ease: 'none'
      },
      scrollTrigger: {
        trigger: heroGallery,
        start : 0,
        end : 'center center',
        scrub: 1
      }
    })

    this.galleryTL.fromTo(visuals[0], { xPercent: 90, rotate: 7 }, { xPercent: 0, rotate: -6 }, 0)
    this.galleryTL.fromTo(visuals[2], { xPercent: -90, rotate: -7 }, { xPercent: 0, rotate: 6 }, 0)

  }

  onLeaveCompleted() {
    this.galleryTL && this.galleryTL.kill(true)
  }
}
