/*
    Load Plugins / Functions
-------------------------------------------------- */
import {Core} from '@unseenco/taxi'
import {globalStorage} from './_globals'
import {onLeave, onEnter, onEnterCompleted} from './_events'

/* --- Renderers --- */
import Page from './pages/page'
import Home from './pages/home'
import PageComponents from './pages/components'
import PageExpertises from './pages/expertises'
import PageContact from "./pages/page-contact";
import SingleJob from "./pages/single-job";
import PageJoin from "./pages/page-join";
import PagePresentation from "./pages/page-presentation";
import ArchivePosts from "./pages/archive-posts";

/* --- Transitions --- */
import PageTransitionDefault from './transitions/page-transition-default'


/* --- Setup Core --- */
globalStorage.taxi = new Core({
  links: 'a:not([target]):not([href^=\\#]):not([data-taxi-ignore]):not(.ab-item)',
  renderers: {
    default: Page,
    home: Home,
    archivePosts: ArchivePosts,
    pageExpertises: PageExpertises,
    pageComponents: PageComponents,
    pageContact: PageContact,
    pagePresentation: PagePresentation,
    singleJob: SingleJob,
    pageJoin: PageJoin
  },
  transitions: {
    default: PageTransitionDefault
  }
})

/* --- Global Events --- */
globalStorage.taxi.on('NAVIGATE_IN', ({to, trigger}) => onEnter(to, trigger))
globalStorage.taxi.on('NAVIGATE_OUT', ({from, trigger}) => onLeave(from, trigger))
globalStorage.taxi.on('NAVIGATE_END', ({to, from, trigger}) => onEnterCompleted(to, from, trigger))
