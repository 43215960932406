import { Renderer } from '@unseenco/taxi';
import { isDEVMODE, viewStorage } from '../_globals'
import WknSwiper from '../modules/wkn-swiper'

export default class Home extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      swiperLogo : viewStorage.current.querySelector('.Partners__logo .swiper'),
      swiperQuote : viewStorage.current.querySelector('.Partners__quote .swiper'),
    }

    this.init()
  }

  init() {
    if (isDEVMODE) console.log(`Init Page : Home`)
    if (isDEVMODE) console.log(viewStorage.current)

    const { swiperLogo, swiperQuote } = this.DOM
    swiperLogo && this.initSwiperLogo()
    swiperQuote && this.initSwiperQuote()
  }

  initSwiperLogo() {
    const { swiperLogo } = this.DOM

    this.swiperLogo = new WknSwiper(swiperLogo, { 
      loop: true,
      centeredSlides : true,
      loopedSlides: 3,
      autoplay: {
        delay: 1,
      }, 
      speed: 3000,
      slidesPerView:'auto',
      allowTouchMove: false,
      spaceBetween: 24,
    })
  }

  initSwiperQuote() {
    const { swiperQuote } = this.DOM
    this.swiperQuote = new WknSwiper(swiperQuote, { 
      slidesPerView: 1,
      effect: "cards",
      cardsEffect: {
        perSlideOffset: 2,
        perSlideRotate: 4, 
      },
      grabCursor: true,
    })
  }

  // onEnterCompleted() {}

  // onLeave() {}

  onLeaveCompleted() {
    const { swiperLogo, swiperQuote } = this
    swiperLogo && swiperLogo.destroy()
    swiperQuote && swiperQuote.destroy()
  }
}
