import { viewStorage, domStorage } from '../_globals'

export const toggleContactPopin = () => {
  // Check if the contact popin exists
  if (!domStorage.contactPopin) return

  // Get the current page data attributes
  const current_page = viewStorage.current.getAttribute('data-taxi-view')
  // Pages we don't want to show the popin
  const pagesWithoutPopin = current_page === 'pageContact' || current_page === 'pageJoin' || current_page === 'singleJob'
  // Hide className
  const className = '--hidden'

  if (pagesWithoutPopin) {
    console.log('')
    domStorage.contactPopin.classList.add(className)
  } else {
    domStorage.contactPopin.classList.remove(className)
  }
}
