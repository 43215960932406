import {Renderer} from '@unseenco/taxi';
import {viewStorage} from '../_globals'
import {buildAjaxUrl} from "../utils";


export default class ArchivePosts extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)

  }

  onEnter() {
    this.DOM = {
      morePosts: viewStorage.current.querySelector('.show_more'),
      pagination: viewStorage.current.querySelector('.pagination'),
      postsContainer: viewStorage.current.querySelector('.News__Archive__list'),
      postLength: viewStorage.current.querySelector('.postLength'),
      totalPostLength: viewStorage.current.querySelector('.totalPostLength'),
      categoriesList: viewStorage.current.querySelector('.categories')
    }

    this.init()
  }

  init() {
    const {
      morePosts,
      categoriesList
    } = this.DOM;

    morePosts.addEventListener('click', (e) => this.showMore(e))

    this.post_count = 0

    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);

    categoriesList.querySelectorAll('li').forEach(category => {
      category.addEventListener('click', (e) => {

        let queryString = ''

        if (category.dataset.value !== 'all') queryString += `?category=${category.dataset.value}`

        window.location = `?${queryString}`
      })
    })

    this.page = 1;

    this.loadPosts()
  }

  // onLeave() {}

  // onLeaveCompleted() {}

  loadPosts() {
    document.body.classList.add('--loading')

    let queryString = window.location.search;
    const urlParams = new URLSearchParams(queryString);
    const category = urlParams.get('category')
    const params = category ? {category: category, page: this.page} : {page: this.page}

    fetch(buildAjaxUrl('ajax_posts', params))
      .then(res => res.json())
      .then(res => {
        this.DOM.postsContainer.innerHTML += res.html;
        this.post_count += res.number_of_posts
        this.DOM.postLength.innerHTML = this.post_count;

        if (res.category_count){
          this.DOM.totalPostLength.innerHTML = res.category_count
        }

        if (res.max_page === this.page) {
          this.DOM.pagination.classList.add('--hidden')
        } else {
          this.DOM.pagination.classList.remove('--hidden')
        }
        document.body.classList.remove('--loading')
      })
  }

  showMore(e) {
    e.preventDefault();
    this.page++;
    this.loadPosts();
  }
}
