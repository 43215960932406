import {Renderer} from '@unseenco/taxi';
import {viewStorage} from '../_globals'
import Form from "../modules/form";

export default class PageContact extends Renderer {

  initialLoad() {

    const onDOMContentLoaded = () => {
      document.removeEventListener('DOMContentLoaded', onDOMContentLoaded)
      this.onEnter()
    }

    document.addEventListener('DOMContentLoaded', onDOMContentLoaded)
  }

  onEnter() {
    this.DOM = {
      contactForm: viewStorage.current.querySelector('.Form'),
      subjectSelect: viewStorage.current.querySelector('#default-subject'),
      filesFieldSet: viewStorage.current.querySelector('#default-files'),
      organizationFieldSet: viewStorage.current.querySelector('.organizationFieldSet'),
      otherInput: viewStorage.current.querySelector('#default-other'),
      allRadio: viewStorage.current.querySelectorAll('input[type=radio]'),
      fileInputs: viewStorage.current.querySelectorAll('input[type=file]')
    }

    this.init()
  }

  init() {

    const {
      contactForm = null,
      subjectSelect = null,
      otherInput = null,
      fileInputs = null
    } = this.DOM

    this.updateFormWithSubject = this.updateFormWithSubject.bind(this)
    this.checkedOtherCheckbox = this.checkedOtherCheckbox.bind(this)

    if (contactForm) this.contactFormModule = new Form(contactForm, 'contact')
    if (subjectSelect) subjectSelect.addEventListener('change', this.updateFormWithSubject)
    if (otherInput) otherInput.addEventListener('input', this.checkedOtherCheckbox)
    if (fileInputs) fileInputs.forEach(fileInput => fileInput.addEventListener('change', this.updateFileName))

    this.updateSelectField()
  }

  updateFileName(e) {
    const {currentTarget} = e
    const {parentNode} = currentTarget
    const {lastElementChild} = parentNode

    if (lastElementChild && lastElementChild.classList.contains('Form__file-name')) lastElementChild.remove()

    let fileName = currentTarget.value.split('\\').pop().substring(0, 25)
    // compare fileName length with 25
    const hasBeenTruncated = fileName.length > 25
    if (hasBeenTruncated) {
      fileName = fileName + '...'
    }

    const span = document.createElement('span')
    span.classList.add('Form__file-name', '--error')
    span.textContent = fileName

    parentNode.appendChild(span)
  }

  updateSelectField() {
    const {
      subjectSelect = null,
    } = this.DOM

    if (!subjectSelect) return

    let params = new URL(document.location).searchParams;

    if (params.get('select') === "apply") {
      subjectSelect.value = "apply"
      this.changeForm(true)
    }
  }

  updateFormWithSubject(e) {
    this.changeForm(e.currentTarget.value === 'apply' ?? null)
  }

  changeForm(apply = null) {
    const {
      filesFieldSet = null,
      organizationFieldSet = null,
    } = this.DOM

    if (!filesFieldSet) return
    if (apply) {
      organizationFieldSet.style.display = 'none'
      organizationFieldSet.getElementsByTagName("input")[0].value = 'apply'
      filesFieldSet.style.display = 'block'
    } else {
      organizationFieldSet.style.display = 'block'
      organizationFieldSet.getElementsByTagName("input")[0].value = ''
      filesFieldSet.style.display = 'none'
    }
  }

  checkedOtherCheckbox() {
    const {
      allRadio = null
    } = this.DOM

    for (let radio of allRadio) {
      if (radio.value === "Autre") radio.checked = true
    }
  }

  onLeaveCompleted() {
    const {fileInputs} = this.DOM
    if (fileInputs) fileInputs.forEach(fileInput => fileInput.removeEventListener('change', this.updateFileName))
    this.contactFormModule && this.contactFormModule.destroy()
  }
}
